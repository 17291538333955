<template>
  <div class="usefulDocuments">
      <el-row type="flex" justify="center"  class=" mt-0 mb-3">
        <el-col :span="24" class="back_action_panel">
          <router-link :to="{ name: 'AdministrationUsefulDocuments' }">
            <el-button class="pt-0" type="text" icon="el-icon-back">Назад</el-button>
          </router-link>
          <el-button class="back_action_panel--first_element" @click="saveUsefulDocuments" :disabled="loading" type="text">
            <SaveIcon />
            Сохранить
          </el-button>
          <el-button v-if="id" class="pt-0" type="text" icon="el-icon-delete" @click="handleDelete()">Удалить</el-button>
          <el-button class="pt-0" type="text" icon="el-icon-circle-close" @click="handleCancel()">Отменить</el-button>
        </el-col>
      </el-row>

        <el-form :rules="rules"
          v-loading="loading"
          ref="usefulDocumentsForm"
          :model="usefulDocuments"
          label-width="15rem"
          :style="{ height: this.cardHeight}"
          id="uploadForm"
          enctype="multipart/form-data" >

          <div class="mb-1">
            <el-form-item label="Заголовок:" prop="header">
                <el-input v-model="usefulDocuments.name" size="small" style="max-width: 22rem;"></el-input>
            </el-form-item>
            <el-form-item label="Разрешения:" prop="header">
              <el-select   multiple  clearable  v-model="usefulDocuments.roles" style="max-width: 22rem;" placeholder="Выбрать">
                <el-option
                  v-for="item in roles"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <span class="mr-2">{{ fileText }}</span>
            <br />
            <div id="file-chosen">
              <label id="file-label" for="file">Обзор</label>
              <div id="file-name"
                :class="{emptyFileName : !(usefulDocuments.fileName || fileName)}">{{ fileName ? fileName : (usefulDocuments.fileName ? usefulDocuments.fileName : 'Выберите файл')}}</div>
            </div>

            <input @change="handleFileChange" id="file" type="file" name="file" hidden/>
          </div>
        </el-form>
  </div>
</template>

<script>

import cloneDeep from 'lodash.clonedeep';
import usefulDocumentsApi from '@/api/usefuldocuments';
import identityApi from '@/api/identity';
import SaveIcon from '@/assets/icons/save.svg';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import tableResize from '@/mixins/tableResize.js';


export default {
  name: 'AdministrationUsefulDocumentsItem',
  props: {
    id: { type: String },
    value: { type: Boolean, default: () => false },
    fileText: { type: String, default: () => 'Выберите файл' }
  },
  mixins: [formatters, dateHelpers, tableResize],
  components: {
    SaveIcon
  },
  data() {
    return {
      fileId: null,
      uploadInProgress: false,
      uploadComplete: false,
      percentage: 0,
      fileName: '',
      loading: false,
      roles: [],
      usefulDocuments: {
        roles: []
      },
      rules: {
        name: [
          { required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' },
          { whitespace: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }
        ],
      },
    };
  },
  async created() {
    this.cardHeight = this.getCardHeight();
    this.loading = true;
    if (this.id) {
      await this.getUsefulDocuments(this.id);
    }
    await this.getRoleGroups();
    this.loading = false;
  },
  methods: {
    async getUsefulDocuments(id) {
      await usefulDocumentsApi.getUsefulDocumentsItem(id).then((response) => {
        this.usefulDocuments = response.data;
        this.cardHeight = this.getCardHeight();
      });
    },
    async getRoleGroups() {
      const res = await identityApi.getRoleGroups();
      this.roles = res.data.items;
    },
    async saveUsefulDocuments() {
      const valid = await this.$refs.usefulDocumentsForm.validate();
      if (!valid) return;

      this.loading = true;

      if (!this.id && !this.fileName) {
        this.$message({
          type: 'warning',
          message: 'Необходимо выбрать файл',
        });

        this.loading = false;
        return;
      }

      this.percentage = 0;
      this.uploadComplete = false;

      const form = document.getElementById('uploadForm');
      const formData = new FormData(form);
      formData.set('name', this.usefulDocuments.name);
      formData.set('id', this.usefulDocuments.id);

      this.usefulDocuments.roles.forEach((el) => {
        formData.append('roles[]', el);
      });

      const config = {
        onUploadProgress: (progressEvent) => {
          this.percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      };
      this.uploadInProgress = true;
      const res = await usefulDocumentsApi.uploadUsefulDocument(formData, config, this.usefulDocuments.name, this.usefulDocuments.roles);
      if (!res.data) {
        this.$alert('Загружаемый документ не содержит информацию или имеет неверный формат, необходимо загрузить корректный файл.', 'Ошибка', {
          confirmButtonText: 'OK',
          type: 'warning',
          customClass: 'alert-30',
        });
        this.uploadInProgress = false;
        this.uploadComplete = true;

        this.loading = false;
        return;
      }
      if (res) {
        this.fileId = res.data;
        this.uploadInProgress = false;
        this.uploadComplete = true;

        this.loading = false;
        this.$router.push({ name: 'AdministrationUsefulDocuments' });
      }
    },
    getSaveable() {
      const result = cloneDeep(this.usefulDocuments);
      return result;
    },

    async handleDelete() {
      this.$confirm('Вы действительно хотите удалить запись?', 'Подтверждение', {
        confirmButtonText: 'ОК',
        cancelButtonText: 'Отмена',
        type: 'warning',
      }).then(async () => {
        this.loading = true;
        await usefulDocumentsApi.deleteUsefulDocuments(this.usefulDocuments.id);
        this.$router.push({ name: 'AdministrationUsefulDocuments' });
        this.loading = false;
      }).catch(() => { });
    },
    async handleCancel() {
      if (!this.usefulDocuments.id) {
        this.$router.push({ name: 'AdministrationUsefulDocuments' });
      } else {
        this.loading = true;
        await this.getUsefulDocuments(this.usefulDocuments.id).then(() => { this.loading = false; });
      }
    },
    handleFileChange(event) {
      this.percentage = 0;
      this.uploadComplete = false;

      this.fileName = event.target.files[0].name;
    },
  }
};
</script>

<style scoped lang="scss">

button.el-button.el-button--text:first-child {
  margin-left: 0px;
}
.el-col>span {
  font-size: 12px;
}
.ml-6 {
    margin-left: calc(5rem - 1vw)!important;
}
.el-form {
  overflow: auto;
}
#file-chosen{
   width:100%;
   button{
      margin-left: 1rem;
   }
 }
</style>
